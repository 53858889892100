import { Helmet } from "react-helmet";
import { Img } from "../../components";
import PageRow from "./PageRow";
import React from "react";

export default function PagePage() {
  return (
    <>
      <Helmet>
        <title> 11</title>
        <meta name="description" content="Web site created using create-react-app" />
      </Helmet>
      <div className="w-full bg-amber-600">
        <header className="bg-white-a700">
          <Img
            src={`images/img_captura_de_pantalla.png`}
            alt="Capturade"
            className="h-[68px] w-full object-cover md:h-auto"
          />
        </header>
        <PageRow />
      </div>
    </>
  );
}
