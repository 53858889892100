import React from "react";
import { useRoutes } from "react-router-dom";
import PageHome from "pages/home";
import NotFound from "pages/NotFound";
import PageConversation from "pages/conversation";
import PageEnding from "pages/ending";
import { ThemeProvider } from 'context/ThemeContext';

const ProjectRoutes = () => {
  let element = useRoutes([
    { path: `/`, element: <ThemeProvider><PageHome /></ThemeProvider> },
    { path: `/conversation`, element: <ThemeProvider><PageConversation /></ThemeProvider> },
    { path: `/ending`, element: <ThemeProvider><PageEnding /></ThemeProvider> },
    { path: "*", element: <NotFound /> },
  ]);

  return element;
};

export default ProjectRoutes;
